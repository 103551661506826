import React from "react";

const Contact = () => {
  return (
    <div>
      <div className="max-w-[1400px] mx-auto text-center pt-5 mt-32">
        <p className="md:text-[48px] text-[32px] font-bold py-3">Contact Us</p>
        <p className="md:w-[50%] px-[16px] md:px-[0px] mx-auto text-[#C2C7D6] text-[16px]">
          We strive to respond to all inquiries as quickly as possible. Your
          questions and feedback are important to us, and we'll do our best to
          address them in a timely manner.
        </p>

        <div className="lg:flex mx-auto gap-[80px] md:px-[80px] px-[12px] md:py-[100px] py-[40px] h-auto">
          <div className="lg:w-[872px]  ">
            <div className="border-2 rounded-[30px] p-[20px]  bg-[#22282D]">
              <form className="md:flex flex-wrap text-start">
                <label class="block md:w-1/2 pt-5 md:px-5">
                  <span class="block text-sm font-medium pb-2">Full Name</span>
                  <input
                    type="text"
                    placeholder="Full Name"
                    className="w-full rounded-lg bg-[#37414A] h-[40px] ps-2"
                  />
                </label>
                <label class="block md:w-1/2 pt-5 md:px-5">
                  <span class="block text-sm font-medium pb-2">
                    Company / Organization
                  </span>
                  <input
                    type="type"
                    placeholder="Company / Organization"
                    className="w-full rounded-lg bg-[#37414A] h-[40px] ps-2"
                  />
                </label>
                <label class="block md:w-1/2 pt-5 md:px-5">
                  <span class="block text-sm font-medium pb-2">Phone</span>
                  <input
                    type="numver"
                    placeholder="Phone"
                    className="w-full rounded-lg bg-[#37414A] h-[40px] ps-2"
                  />
                </label>
                <label class="block md:w-1/2 pt-5 md:px-5">
                  <span class="block text-sm font-medium pb-2">Email</span>
                  <input
                    type="email"
                    placeholder="Email"
                    className="w-full rounded-lg bg-[#37414A] h-[40px] ps-2"
                  />
                </label>
                <label class="block md:w-1/2 pt-5 md:px-5">
                  <span class="block text-sm font-medium pb-2">Service</span>
                  {/* <input
                    type="text"
                    placeholder="Services"
                    className="w-full rounded-lg bg-[#37414A] h-[40px] ps-2"
                  /> */}
                  <select class="bg-[#37414A] h-[40px] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option selected>Choose a service</option>
                    <option value="Website Development">
                      Website Development{" "}
                    </option>
                    <option value="App Development">App Development</option>
                    <option value="Ecommerce Solutions">
                      Ecommerce Solutions
                    </option>
                  </select>
                </label>
                <label class="block md:w-1/2 pt-5 md:px-5">
                  <span class="block text-sm font-medium pb-2">Products</span>
                  {/* <input
                    type="text"
                    placeholder="Products"
                    className="w-full rounded-lg bg-[#37414A] h-[40px] ps-2"
                  /> */}
                  <select class="bg-[#37414A] h-[40px] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option selected>Choose a product</option>
                    <option value="Learning Management System">
                      Learning Management System
                    </option>
                    <option value="School Management System">
                      School Management System
                    </option>
                    <option value="Payment Gateway">Payment Gateway</option>
                    <option value="SMS Gateway">SMS Gateway</option>
                    <option value="Clinic Management System">
                      Clinic Management System
                    </option>
                    <option value="Hotel Management System">
                      Hotel Management System
                    </option>
                    <option value="Others">Others</option>
                  </select>
                </label>
                <label class="block w-full pt-5 md:px-5">
                  <span class="block text-sm font-medium pb-2">Subject</span>
                  <input
                    type="text"
                    placeholder="Subject"
                    className="w-full rounded-lg bg-[#37414A] h-[40px] ps-2"
                  />
                </label>
                <label class="block w-full pt-5 md:px-5">
                  <span class="block text-sm font-medium pb-2">Message</span>
                  <textarea
                    id="message"
                    rows="4"
                    class="block p-2.5 w-full text-sm bg-[#37414A] rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write your thoughts here..."
                  ></textarea>
                </label>

                <div class="flex md:items-center items-top md:m-5 mt-5 md:mt-10 ">
                  <input
                    id="link-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 md:mt-0 mt-1 md:me-0 me-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="link-checkbox"
                    class="md:ms-2 text-[16px] font-medium"
                  >
                    I agree with terms of usage and privacy policy.
                  </label>
                </div>

                <button
                  type="button"
                  style={{
                    background:
                      "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                  }}
                  className="w-[110px] rounded-full m-5 mt-10 ms-auto text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Submit
                </button>
              </form>
            </div>
            <div className="border-2 mt-5 rounded-lg  bg-[#22282D]">
              <div className="md:flex container items-center py-1 mx-auto">
                <div className="md:w-1/3 text-center p-8">
                  <img
                    style={{ widows: "40px", height: "36px" }}
                    src="/photos/Icon7.png"
                    alt=""
                    className="mx-auto m-2"
                  />
                  <p className="text-xl">Sales and Business</p>
                  <p className=" px-3 text-[#A3AAC2] text-[16px]">
                    sales@cbs.com.mm
                  </p>
                </div>
                <div
                  style={{ background: "white" }}
                  className="md:h-16 md:w-1 h-[1px] w-16 mx-auto  bg-white"
                ></div>
                <div className="md:w-1/3 text-center p-8">
                  <img
                    style={{ widows: "40px", height: "36px" }}
                    src="/photos/Icon8.png"
                    alt=""
                    className="mx-auto m-2"
                  />
                  <p className="text-xl">Partners</p>
                  <p className=" px-3 text-[#A3AAC2] text-[16px]">
                    partner@cbs.com.mm
                  </p>
                </div>
                <div
                  style={{ background: "white" }}
                  className="md:h-16 md:w-1 h-[1px] w-16 mx-auto  bg-white"
                ></div>
                <div className="md:w-1/3 text-center mx-auto p-8">
                  <img
                    style={{ widows: "40px", height: "36px" }}
                    src="/photos/Icon9.png"
                    alt=""
                    className="mx-auto m-2"
                  />
                  <p className="text-xl">Customer Support</p>
                  <p className="text-[#A3AAC2] text-[16px]">
                    support@cbs.com.mm
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-[328px] hidden lg:block ">
            {" "}
            <img
              className=" object-cover rounded-lg mx-auto"
              src="/photos/Box.png"
              alt=""
            />
          </div>
          {/* <img className="col-7 w-full" src="/photos/photo3.jpg" alt="" />
          <img className="col-5" src="/photos/photo4.jpg" alt="" /> */}
        </div>
      </div>

      <div className="py-8 text-center md:px-[80px] px-[12px]">
        <button
          style={{ background: "#00D7EA45", width: "100px", height: "30px" }}
          className="rounded-full text-[16px] border-1 px-1"
        >
          <span className="w-full text-[#00B7FF]">Our Office</span>
        </button>
        <p className="md:text-[32px] text-[24px] font-simibold pt-[20px]">
          Here to find
        </p>
        <p className="text-[#C2C7D6] pt-[20px] text-[16px] md:w-[50%] mx-auto">
          Experience our expansive network of offices across the world,
          connecting you to our exceptional services wherever you are.
        </p>

        <div className="mt-[48px] mx-auto rounded-lg max-w-[1280px]">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3818.1720588592443!2d96.12701707289827!3d16.86738108209172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30c1950c4161e84f%3A0x94f7ead79a434d01!2sConnected%20Business%20Solutions!5e0!3m2!1sen!2shk!4v1707554170190!5m2!1sen!2shk"
            width="100%"
            height="500px"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="rounded-lg"
          ></iframe>
        </div>
      </div>

      <div
        style={{
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="max-w-[1280px] card2bg md:mx-[80px] md:my-[120px] lg:mx-auto p-8 rounded-xl md:mb-28 mb-12 mt-12  mx-[16px] md:mx-[0px] text-center"
      >
        <p className="md:text-5xl text-[16px] font-bold py-3 mx-auto">
          Our Dedicated Team is Here <br />
          to provide you
        </p>
        <p className="md:text-[16px] text-[12px]">
          Requesting a demo is the perfect way to explore the features,
          benefits, and possibilities that our solution offers.
        </p>
        <div className="relative mx-auto my-3 md:h-[45px] h-[30px] md:w-[513px] w-[274px]">
          <input
            className="w-full h-full rounded-full text-black text-[12px] md:text-[16px] ps-3"
            type="text"
            placeholder="Enter Your Email"
          />
          <button
            style={{
              background:
                "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            className="md:w-32 w-[74px] md:h-[36px] h-[22px] rounded-full md:text-[16px] text-[10px] md:right-[6px] right-[5px]"
          >
            Get started
          </button>
        </div>

        <p className="md:text-[14px] text-[12px] md:w-[400px] mx-auto">
          By clicking Sign Up you're confirming that you agree with our Terms
          and Conditions.
        </p>
      </div>
    </div>
  );
};

export default Contact;
