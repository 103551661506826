import React, { useEffect } from "react";
import { gsap } from "gsap";
import "./style.css";

const Gsap = () => {
  useEffect(() => {
    const tl1 = gsap.timeline({ repeat: -1 });
    tl1
      .to(".image1", {
        duration: 2,
        top: 0,
        left: "50%",
        ease: "power1.out",
      })
      .to(".image1", {
        duration: 2,
        delay: 2,
        left: "-100%",
        ease: "power1.out",
      })
      .to(".image2", {
        duration: 2,
        top: 0,
        left: "50%",
        ease: "power1.out",
      })
      .to(".image2", {
        duration: 2,
        delay: 2,
        left: "-100%",
        ease: "power1.out",
      })
      .to(".image3", {
        duration: 2,
        top: 0,
        left: "50%",
        ease: "power1.out",
      })
      .to(".image3", {
        duration: 2,
        delay: 2,
        left: "-100%",
        ease: "power1.out",
      });

    const tl2 = gsap.timeline({ repeat: -1, delay: 1 });
    tl2
      .to(".image4", {
        duration: 2,
        top: 0,
        // left: 0,
        ease: "power1.out",
      })
      .to(".image4", {
        duration: 2,
        delay: 2,
        left: "200%",
        ease: "power1.out",
      })
      .to(".image5", {
        duration: 2,
        top: 0,
        // left: 0,
        ease: "power1.out",
      })
      .to(".image5", {
        duration: 2,
        delay: 2,
        left: "200%",
        ease: "power1.out",
      })
      .to(".image6", {
        duration: 2,
        top: 0,
        // left: 0,
        ease: "power1.out",
      })
      .to(".image6", {
        duration: 2,
        delay: 2,
        left: "200%",
        ease: "power1.out",
      });

    const tl3 = gsap.timeline({ repeat: -1, delay: 2 });
    tl3
      .to(".image7", {
        duration: 2,
        top: 0,
        // left: 0,
        ease: "power1.out",
      })
      .to(".image7", {
        duration: 2,
        delay: 2,
        left: "200%",
        ease: "power1.out",
      })
      .to(".image8", {
        duration: 2,
        top: 0,
        // left: 0,
        ease: "power1.out",
      })
      .to(".image8", {
        duration: 2,
        delay: 2,
        left: "200%",
        ease: "power1.out",
      })
      .to(".image9", {
        duration: 2,
        top: 0,
        // left: 0,
        ease: "power1.out",
      })
      .to(".image9", {
        duration: 2,
        delay: 2,
        left: "200%",
        ease: "power1.out",
      });
  }, []);

  return (
    <div className="md:flex justify-around">
      <div className="box mx-auto my-14 md:my-0">
        <img
          className="image1 min-w-[120px] h-[60px] object-cover"
          // src="https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=338&ext=jpg&ga=GA1.1.1700460183.1709424000&semt=aishttps://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=626&ext=jpg"
          src="/photos/comLogo1.png"
          alt="Image 1"
        />
        <img
          className="image2 min-w-[120px] h-[60px] object-cover"
          // src="https://img.freepik.com/free-vector/golden-bird-logo-design_1195-336.jpg?size=626&ext=jpg"
          src="/photos/comLogo2.png"
          alt="Image 2"
        />
        <img
          className="image3 min-w-[120px] h-[60px] object-cover"
          // src="https://img.freepik.com/free-vector/gradient-quill-pen-design-template_23-2149837194.jpg?size=626&ext=jpg"
          src="/photos/comLogo3.png"
          alt="Image 3"
        />
      </div>
      <div className="box2 mx-auto my-14 md:my-0">
        <img
          className="image4 min-w-[120px] h-[60px] object-cover"
          // src="https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=338&ext=jpg&ga=GA1.1.1700460183.1709424000&semt=aishttps://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=626&ext=jpg"
          src="/photos/comLogo2.png"
          alt="Image 4"
        />
        <img
          className="image5 min-w-[120px] h-[60px] object-cover"
          // src="https://img.freepik.com/free-vector/golden-bird-logo-design_1195-336.jpg?size=626&ext=jpg"
          src="/photos/comLogo3.png"
          alt="Image 5"
        />
        <img
          className="image6 min-w-[120px] h-[60px] object-cover"
          // src="https://img.freepik.com/free-vector/gradient-quill-pen-design-template_23-2149837194.jpg?size=626&ext=jpg"
          src="/photos/comLogo4.png"
          alt="Image 6"
        />
      </div>
      <div className="box mx-auto my-14 md:my-0">
        <img
          className="image7 min-w-[120px] h-[60px] object-cover"
          // src="https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=338&ext=jpg&ga=GA1.1.1700460183.1709424000&semt=aishttps://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=626&ext=jpg"
          src="/photos/comLogo3.png"
          alt="Image 1"
        />
        <img
          className="image8 min-w-[120px] h-[60px] object-cover"
          // src="https://img.freepik.com/free-vector/golden-bird-logo-design_1195-336.jpg?size=626&ext=jpg"
          src="/photos/comLogo4.png"
          alt="Image 2"
        />
        <img
          className="image9 min-w-[120px] h-[60px] object-cover"
          // src="https://img.freepik.com/free-vector/gradient-quill-pen-design-template_23-2149837194.jpg?size=626&ext=jpg"
          src="/photos/comLogo5.png"
          alt="Image 3"
        />
      </div>
      <div className="box2 mx-auto my-14 md:my-0">
        <img
          className="image4 min-w-[120px] h-[60px] object-cover"
          // src="https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=338&ext=jpg&ga=GA1.1.1700460183.1709424000&semt=aishttps://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=626&ext=jpg"
          src="/photos/comLogo4.png"
          alt="Image 4"
        />
        <img
          className="image5 min-w-[120px] h-[60px] object-cover"
          // src="https://img.freepik.com/free-vector/golden-bird-logo-design_1195-336.jpg?size=626&ext=jpg"
          src="/photos/comLogo5.png"
          alt="Image 5"
        />
        <img
          className="image6 min-w-[120px] h-[60px] object-cover"
          // src="https://img.freepik.com/free-vector/gradient-quill-pen-design-template_23-2149837194.jpg?size=626&ext=jpg"
          src="/photos/comLogo1.png"
          alt="Image 6"
        />
      </div>
      <div className="box mx-auto my-14 md:my-0">
        <img
          className="image1 min-w-[120px] h-[60px] object-cover"
          // src="https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=338&ext=jpg&ga=GA1.1.1700460183.1709424000&semt=aishttps://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=626&ext=jpg"
          src="/photos/comLogo5.png"
          alt="Image 1"
        />
        <img
          className="image2 min-w-[120px] h-[60px] object-cover"
          // src="https://img.freepik.com/free-vector/golden-bird-logo-design_1195-336.jpg?size=626&ext=jpg"
          src="/photos/comLogo1.png"
          alt="Image 2"
        />
        <img
          className="image3 min-w-[120px] h-[60px] object-cover"
          // src="https://img.freepik.com/free-vector/gradient-quill-pen-design-template_23-2149837194.jpg?size=626&ext=jpg"
          src="/photos/comLogo2.png"
          alt="Image 3"
        />
      </div>
    </div>
  );
};

export default Gsap;
