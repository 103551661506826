import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./index.css";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";

export default function Slider() {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + "</span>";
    },
  };

  return (
    <>
      <Swiper
        pagination={pagination}
        // modules={[Pagination]}
        modules={[Pagination, Autoplay]}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        className="mySwiper text-white"
      >
        <SwiperSlide className="text-white">
          {" "}
          <div className="lg:flex md:p-5 md:m-5">
            <div className="lg:w-2/3 text-left p-5">
              {" "}
              <p className="md:text-4xl text-[20px] font-simibold py-5">
                Gamone Pwint
              </p>
              <p className="text-[#C2C7D6] md:text-[19px] text-[16px]">
                CBS work with Gamone Pwint-Shopping online which focused on
                enhancing B2C shopping experience and bringing new features that
                make online shopping even more convenient and Gamonepwint
                eCommerce platforms offer on website and mobile apps (
                iOS,Andriod ).
              </p>
              {/* <p className="text-[#C2C7D6] md:text-[19px] text-[16px]">
                Gamonepwint eCommerce platforms offer a seamless and convenient
                shopping experience, with a wide array of products, personalized
                recommendations, and flexible payment and delivery options,
                enhancing customer satisfaction and loyalty. User friendly
                interface, Product Catalog Management , Order Management to
                checkout process smoothly including we provide the integration
                with our own Payment Gateway platform.
              </p>
              <p className="text-[#C2C7D6] md:text-[19px] text-[16px]">
                These features and functions collectively contribute to creating
                a robust and user-friendly eCommerce platform that delivers. a
                seamless and satisfying shopping experience for customers while
                enabling businesses to manage their operations efficiently and
                effectively. It can involve any retail experience occur on
                website and mobile apps ( iOS,Andriod ).
              </p> */}
              <ul className="list-disc m-5 text-[16px] text-[#C2C7D6]">
                <li>
                  Seamless convenient shopping experience, User friendly
                  interface,
                </li>
                <li>Wide array of products SKUs, Product Catalog Management</li>
                <li>Personalized recommendations,</li>
                <li>
                  Flexible payment and delivery options, enhancing customer
                  satisfaction and loyalty.
                </li>
                <li>
                  Order Management to checkout process smoothly including we
                  provide the integration with CBS's Payment Gateway.
                </li>
              </ul>
              <div className="mt-5">
                <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  IT Consulting
                </button>
                <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  Mobile Development
                </button>
                <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  Quality Assurance
                </button>
                {/* <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  Web Development
                </button> */}
                <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  UI/UX Design
                </button>
                {/* <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  DevOps
                </button> */}
              </div>
            </div>
            <div className="lg:w-1/3 ">
              <img
                className="w-[460px] md:h-[500px]"
                src="/photos/successStory1.png"
                alt=""
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="text-white">
          {" "}
          <div className="lg:flex md:p-5 md:m-5">
            <div className="lg:w-2/3 text-left p-5">
              {" "}
              <p className="md:text-4xl text-[20px] font-simibold py-5">
                My Pages
              </p>
              <p className="text-[#C2C7D6] md:text-[19px] text-[16px]">
                CBS deliver the Ebook Platform to My Pages who is selling books
                online by providing readers with a seamless purchasing
                experience professionally. Ebook selling platforms typically
                offer a variety of features to help authors and publishers
                effectively market and sell their ebooks to readers.
              </p>
              <ul className="list-disc m-5 text-[16px] text-[#C2C7D6]">
                <li>
                  To showcase My Pages' books and provide a seamless shopping
                  experience to their customers.
                </li>
                <li>
                  Offer various templates and themes to choose from, making it
                  easy to design.
                </li>
                <li>
                  Selling eBooks to people for their digital devices or offering
                  trade-in as part of your used bookstore experience.
                </li>
                <li>
                  By incorporating these features, ebook selling platforms
                  empower authors and publishers to effectively market and sell
                  their ebooks to a global audience.
                </li>
              </ul>
              <div className="mt-5">
                <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  IT Consulting
                </button>
                <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  Mobile Development
                </button>
                <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  Quality Assurance
                </button>
                {/* <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  Web Development
                </button> */}
                <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  UI/UX Design
                </button>
                {/* <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  DevOps
                </button> */}
              </div>
            </div>
            <div className="lg:w-1/3 ">
              <img
                className="w-[460px] md:h-[500px]"
                src="/photos/successStory/two.png"
                alt=""
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="text-white">
          {" "}
          <div className="lg:flex md:p-5 md:m-5">
            <div className="lg:w-2/3 text-left p-5">
              {" "}
              <p className="md:text-4xl text-[20px] font-simibold py-5">
                Dr.Glow
              </p>
              <p className="text-[#C2C7D6] md:text-[19px] text-[16px]">
                CBS deliver the the Clinic Managment System to Dr. Glow
                Aesthetic Clinic which has long been dedicated to providing
                exceptional aesthetic care to its patients.
              </p>
              <ul className="list-disc m-5 text-[16px] text-[#C2C7D6]">
                <li>
                  In pursuit of enhanced patient care and streamlined
                  operations, embarked on a journey to implement a
                  state-of-the-art Clinic Management System (CMS).
                </li>
                <li>
                  This success story illuminates the transformative impact of
                  this technological upgrade on Dr. Glow Aesthetic Clinic.
                </li>
                <li>
                  The integration of a Clinic Management System revolutionized
                  operations at Dr. Glow Aesthetic Clinic.
                </li>
                <li>
                  By prioritizing patient care and leveraging innovative
                  solutions, and the team at Dr. Glow Aesthetic Clinic continue
                  to set new standards for excellence in aesthetic care.
                </li>
              </ul>
              <div className="mt-5">
                <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  IT Consulting
                </button>
                <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  Mobile Development
                </button>
                <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  Quality Assurance
                </button>
                {/* <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  Web Development
                </button> */}
                <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  UI/UX Design
                </button>
                {/* <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  DevOps
                </button> */}
              </div>
            </div>
            <div className="lg:w-1/3 ">
              <img
                className="w-[460px] md:h-[500px]"
                src="/photos/successStory/three.png"
                alt=""
              />
            </div>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide className="text-white">
          {" "}
          <div className="lg:flex md:p-5 md:m-5">
            <div className="lg:w-2/3 text-left p-5">
              {" "}
              <p className="md:text-4xl text-[20px] font-simibold py-5">
                E-commerce System
              </p>
              <p className="text-[#C2C7D6] md:text-[19px] text-[16px]">
                Our technology solutions streamline and optimize business
                operations, automating processes, enhancing efficiency, and
                improving overall productivity. From seamless inventory
                management to streamlined customer relationship management, our
                solutions empower businesses to operate at their peak potential,
                saving time and resources while maximizing profitability.s
              </p>
              <ul className="list-disc m-5 text-[16px] text-[#C2C7D6]">
                <li>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                </li>
                <li>
                  sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.{" "}
                </li>
                <li>
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.{" "}
                </li>
                <li>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur see more...
                </li>
              </ul>
              <div>
                <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  IT Consulting
                </button>
                <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  Mobile Development
                </button>
                <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  Quality Assurance
                </button>
                // <button
                //   style={{ borderColor: "#00D7EA" }}
                //   className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                // >
                //   Web Development
                // </button>
                <button
                  style={{ borderColor: "#00D7EA" }}
                  className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                >
                  UI/UX Design 
                </button>
                // <button
                //   style={{ borderColor: "#00D7EA" }}
                //   className="border rounded-full mr-2 p-1 md:text-[18px] text-[16px] mb-3 px-2"
                // >
                //   DevOps
                // </button>
              </div>
            </div>
            <div className="lg:w-1/3 ">
              <img
                className="w-[460px] md:h-[500px]"
                src="/photos/successStory1.png"
                alt=""
              />
            </div>
          </div>
        </SwiperSlide> */}
      </Swiper>
    </>
  );
}
