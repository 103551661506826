import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./index.css";

// import required modules
import { Autoplay, Navigation } from "swiper/modules";
import { ArrowLeft, ArrowRight, Paperclip, Send } from "react-feather";

export default function Swipertwo() {
  const swiperRef = useRef();
  return (
    <>
      <Swiper
        loop={true}
        modules={[Navigation, Autoplay]}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        className="Swipertwo text-white h-auto"
      >
        <SwiperSlide>
          <div className="lg:flex items-center">
            <div
              style={{ background: "#22282D" }}
              className="text-start min-h-[272px] lg:w-1/2"
            >
              <button
                style={{
                  background:
                    "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                }}
                className="rounded-full text-[16px] border-1 w-[120px] h-[40px]"
              >
                <span className="w-full">Our Services</span>
              </button>
              <p className="md:text-[32px] text-[20px] font-simibold py-5">
                Website Development
              </p>
              <p className="text-[#C2C7D6] md:text-[16px] text-[16px]">
                CBS provide the website development which offers to the process
                of creating, designing, and maintaining websites. It involves
                various tasks, including web design, web programming, content
                creation, and web server configuration, to build functional and
                visually appealing websites. Overview of the key aspects of
                website development:
              </p>

              <p className="text-[#C2C7D6] md:text-[16px] text-[16px] flex gap-3 pt-1">
                {" "}
                <Send /> <span>Static Websites </span>
              </p>
              <p className="text-[#C2C7D6] md:text-[16px] text-[16px] flex gap-3 pt-1">
                {" "}
                <Send /> <span>Dynamic Websites </span>
              </p>
              <p className="text-[#C2C7D6] md:text-[16px] text-[16px] flex gap-3 pt-1">
                {" "}
                <Send /> <span>Content Management Systems (CMS) </span>
              </p>
              <p className="text-[#C2C7D6] md:text-[16px] text-[16px] flex gap-3 pt-1">
                {" "}
                <Send /> <span>Web Applications, Responsive Websites </span>
              </p>
            </div>

            <div className="lg:w-1/2 mt-4 lg:mt-0">
              <img
                style={{ objectFit: "cover" }}
                className="md:w-full lg:max-w-[465px] lg:h-[350px] 2xl:max-w-[650px] object-cover rounded-xl md:max-h-[400px] mb-4 md:mb-0 lg:ms-auto "
                src="/photos/devPhotos/one.png"
                alt=""
              />
            </div>
          </div>
        </SwiperSlide>
        {/* Item 2 */}
        <SwiperSlide>
          <div className="lg:flex items-center">
            <div
              style={{ background: "#22282D" }}
              className="text-start min-h-[272px] lg:w-1/2"
            >
              <button
                style={{
                  background:
                    "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                }}
                className="rounded-full text-[16px] border-1 w-[120px] h-[40px]"
              >
                <span className="w-full">Our Services</span>
              </button>
              <p className="md:text-[32px] text-[20px] font-simibold py-2">
                E-commerce Solutions
              </p>
              <p className="text-[#C2C7D6] md:text-[16px] text-[16px]">
                CBS's E-commerce offers to the buying and selling of goods or
                services throung the internet, typically offer a variety of
                features to enhance the online shopping experience for
                customers.
              </p>
              <div className="flex items-top text-[#C2C7D6] md:text-[16px] text-[16px] flex gap-3 pt-1">
                {" "}
                <div>
                  <Send width={"25px"} height={"25px"} />{" "}
                </div>
                <p>
                  B2C (Business-to-Consumer) e-commerce offers to the online
                  transactions between businesses and individual consumers which
                  can sell products or services directly to end-users through
                  digital channels such as websites, mobile apps, or online
                  marketplaces.
                </p>{" "}
              </div>
              <p className="text-[#C2C7D6] md:text-[16px] text-[16px] flex gap-3 pt-1">
                {" "}
                <div>
                  <Send width={"25px"} height={"25px"} />{" "}
                </div>
                <span>
                  {" "}
                  B2B ( Business-to-Business) e-commerce plays a crucial role in
                  facilitating trade and commerce between businesses, driving
                  operational efficiency, cost savings, and revenue growth for
                  both buyers, sellers, manufacturers, wholesalers,
                  distributors, and retailers.
                </span>
              </p>
            </div>
            <div className="lg:w-1/2 mt-4 lg:mt-0 rounded-xl">
              <img
                style={{ objectFit: "cover" }}
                className="md:w-full lg:max-w-[465px] lg:min-h-[350px] object-cover 2xl:max-w-[650px] rounded-xl md:max-h-[400px] mb-4 md:mb-0 lg:ms-auto "
                src="/photos/devPhotos/two.png"
                alt=""
              />
            </div>
          </div>
        </SwiperSlide>
        {/* Item 3 */}
        <SwiperSlide>
          <div className="lg:flex items-center">
            <div
              style={{ background: "#22282D" }}
              className="text-start min-h-[272px] lg:w-1/2"
            >
              <button
                style={{
                  background:
                    "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                }}
                className="rounded-full text-[16px] border-1 w-[120px] h-[40px]"
              >
                <span className="w-full">Our Services</span>
              </button>
              <p className="md:text-[32px] text-[20px] font-simibold py-5">
                Application Development
              </p>
              <p className="text-[#C2C7D6] md:text-[16px] text-[16px]">
                CBS can deliver the Application development Service which is the
                process of creating software applications for specific purposes,
                such as business productivity, communication, entertainment,
                education, or gaming. It involves several stages, from
                conceptualization and planning to design, development, testing,
                deployment, and maintenance.
              </p>
              <p className="text-[#C2C7D6] md:text-[16px] text-[16px] flex gap-3 pt-1">
                {" "}
                <div>
                  <Send width={"25px"} height={"25px"} />{" "}
                </div>
                <span>
                  Application development can be tailored to various platforms
                  and technologies, including web applications, mobile apps
                  (iOS, Android), desktop applications, and hybrid or
                  cross-platform applications.
                </span>{" "}
              </p>
              {/* <p className="text-[#C2C7D6] md:text-[16px] text-[16px] flex gap-3 pt-1">
                {" "}
                <Send width={"40px"} height={"40px"} />{" "}
                <span>
                  {" "}
                  B2B ( Business-to-Business) e-commerce plays a crucial role in
                  facilitating trade and commerce between businesses,
                </span>
              </p> */}
            </div>
            <div className="lg:w-1/2 mt-4 lg:mt-0">
              <img
                style={{
                  objectFit: "cover",
                }}
                className="md:w-full lg:max-w-[465px] lg:min-h-[350px] 2xl:max-w-[650px] object-cover rounded-xl md:max-h-[400px] mb-4 md:mb-0 lg:ms-auto "
                // className="md:w-full lg:h-[350px] lg:w-[465px] object-cover rounded-xl"
                src="/photos/devPhotos/three.png"
                alt=""
              />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      {/* <div>
        <button onClick={() => swiperRef.current?.slidePrev()}>Prev</button>
        <button onClick={() => swiperRef.current?.slideNext()}>Next</button>
      </div> */}
      <div
        className="flex lg:justify-center justify-end md:mt-2 lg:mt-2"
        style={{ marginLeft: "auto", width: "auto", height: "auto" }}
      >
        <button
          type="button"
          className="w-auto start-0 z-30 flex items-center justify-center h-full px-1 cursor-pointer group focus:outline-none"
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <div
            style={{
              // background:
              //   "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",

              borderColor:
                "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
              width: "50px",
              height: "50px",
            }}
            className="rounded-full relative border-2"
          >
            <ArrowLeft
              // style={{
              //   color: "red",
              // }}
              class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        </button>
        <button
          type="button"
          className="end-0 z-30 flex items-center justify-center h-full px-1 cursor-pointer group focus:outline-none"
          onClick={() => swiperRef.current?.slideNext()}
        >
          <div
            style={{
              background:
                "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
              width: "50px",
              height: "50px",
            }}
            className="rounded-full relative"
          >
            <ArrowRight class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
          </div>
        </button>
      </div>
    </>
  );
}
