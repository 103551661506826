import {
  AnimatePresence,
  motion,
  useMotionValue,
  useTransform,
  animate,
} from "framer-motion";
import { useEffect, useState } from "react";
import CursorBlinker from "./CursorBlinker";

const Hero = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    "/hero/one_50.jpg",
    "/hero/two_50.jpg",
    "/hero/three_50.jpg",
    "/hero/four_50.jpg",
  ];
  const imageVariants = {
    hidden: { scale: 1, opacity: 0 },
    visible: { scale: [1, 1.2], opacity: 1 },
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval); // Clean up interval on unmount
  }, []);

  const textIndex = useMotionValue(0);
  const texts = [
    "Application Development!",
    "Website Development!",
    "E-commerce Solutions!",
    "School Management System!",
    "Hotel Management System!",
    "Clinic Management System!",
  ];
  const baseText = useTransform(textIndex, (latest) => texts[latest] || "");
  const count = useMotionValue(0);
  const rounded = useTransform(count, (latest) => Math.round(latest));
  const displayText = useTransform(rounded, (latest) =>
    baseText.get().slice(0, latest)
  );
  const updatedThisRound = useMotionValue(true);

  // console.log(displayText);
  // console.log(rounded);

  useEffect(() => {
    animate(count, 28, {
      type: "tween",
      duration: 3,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse",
      repeatDelay: 0.5,
      onUpdate(latest) {
        if (updatedThisRound.get() === true && latest > 0) {
          updatedThisRound.set(false);
        } else if (updatedThisRound.get() === false && latest === 0) {
          if (textIndex.get() === texts.length - 1) {
            textIndex.set(0);
            console.log(textIndex.get());
          } else {
            textIndex.set(textIndex.get() + 1);
          }
          // animate.set(count, 28);
          // animate.restart();

          updatedThisRound.set(true);
        }
      },
      // onUpdate(latest) {
      //   if (updatedThisRound.get() === true && latest > 0) {
      //     updatedThisRound.set(false);
      //   } else if (updatedThisRound.get() === false && latest === 0) {
      //     if (textIndex.get() < texts.length - 1) {
      //       // Update content to next item
      //       textIndex.set(textIndex.get() + 1);
      //     } else {
      //       // Reset to the first item if it's the last index
      //       textIndex.set(0);
      //     }
      //     // Restart animation after updating content
      //     animate.restart();
      //     updatedThisRound.set(true);
      //   }
      // },
    });
  }, []);

  return (
    <>
      {/* // z-index -20 */}
      <div className="relative flex justify-start h-screen overflow-hidden -z-20">
        {/* z-index 0 */}
        <AnimatePresence mode="wait">
          <motion.img
            key={currentImage} // Add key to force re-render on image change
            src={images[currentImage]}
            alt="Placeholder"
            className="object-cover w-full h-screen transition-transform duration-[8000ms] ease-linear absolute"
            variants={imageVariants}
            transition={{
              delay: 0.3,
              duration: 5,
              type: "spring",
              opacity: {
                duration: 0.7,
                ease: "easeInOut",
              },
            }}
            initial="hidden"
            animate="visible"
            exit={{ opacity: 0 }}
          />
        </AnimatePresence>

        {/* Preload next image before previous image?? */}
        {/* z-index -10 */}
        <AnimatePresence>
          <motion.img
            key={currentImage} // Add key to force re-render on image change
            src={images[currentImage]}
            alt="Placeholder"
            className="absolute object-cover w-full h-screen -z-10"
          />
        </AnimatePresence>
        <div
          style={{ zIndex: "100" }}
          className="md:w-[900px] md:pt-[252px] md:pt-[150px] 2xl:ml-[400px] 2xl:pt-[300px] pt-[300px] lg:ml-[140px] md:ml-[50px] "
          data-aos="fade-up"
        >
          <p
            style={{
              fontWeight: 700,
              zIndex: "100",
            }}
            className="text-white text-[32px] md:text-[48px] md:text-left md:leading-tight leading-9"
          >
            {/* Instant collaboration <br /> for remote teams */}
            Elevate Your Digital Transformation Landscape with <br />
            CBS’s{" "}
            <span
              className="text-[#018CF1] text-[32px] md:text-[48px] md:text-left
          md:leading-tight leading-9"
            >
              {" "}
              <motion.span className="inline">{displayText}</motion.span>
              <CursorBlinker />
            </span>
          </p>
        </div>
        {/* <span className="absolute p-1 text-2xl transition-all text-amber-400 ">
          <motion.span className="inline">{displayText}</motion.span>
          <CursorBlinker />
        </span> */}
      </div>
    </>
  );
};

export default Hero;

// import {
//   AnimatePresence,
//   motion,
//   useMotionValue,
//   useTransform,
//   animate,
// } from "framer-motion";
// import { useEffect, useState } from "react";

// import CursorBlinker from "../components/CursorBlinker";

// const Hero = () => {
//   const [currentImage, setCurrentImage] = useState(0);

//   const images = ["/hero/1.jpg", "/hero/2.jpg", "/hero/3.jpg", "/hero/4.jpg"];

//   const imageVariants = {
//     hidden: { scale: 1, opacity: 0 },
//     visible: { scale: [1, 1.2], opacity: 1 },
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
//     }, 5000); // Change image every 5 seconds

//     return () => clearInterval(interval); // Clean up interval on unmount
//   }, []);
//   return (
//     // z-index -20
//     <div className="relative flex justify-start h-screen overflow-hidden -z-20">
//       <div
//         style={{ zIndex: "100" }}
//         className="md:w-[678px] md:pt-[252px] md:pt-[150px] 2xl:ml-[400px] 2xl:pt-[300px] pt-[300px] lg:ml-[140px] md:ml-[50px]"
//       >
//         <p
//           style={{
//             fontWeight: 700,
//             zIndex: "100",
//           }}
//           className="text-white text-[32px] md:text-[42px] md:text-left md:leading-tight leading-9"
//         >
//           {/* Instant collaboration <br /> for remote teams */}
//           Elevate Your Digital Transformation Landscape with <br />
//           CBS’s Digital Solutions
//         </p>
//       </div>
//       <AnimatePresence mode="wait">
//         <motion.img
//           key={currentImage}
//           src={images[currentImage]}
//           alt="Placeholder"
//           className="object-cover w-full h-screen transition-transform duration-[8000ms] ease-linear absolute"
//           variants={imageVariants}
//           transition={{
//             delay: 0.3,
//             duration: 4,
//             type: "spring",
//             opacity: {
//               duration: 0.7,
//               ease: "easeInOut",
//             },
//           }}
//           initial="hidden"
//           animate="visible"
//           exit={{ opacity: 0 }}
//         />
//       </AnimatePresence>

//       {/* Preload next image before previous image?? */}
//       {/* z-index -10 */}
//       <AnimatePresence>
//         <motion.img
//           key={currentImage} // Add key to force re-render on image change
//           src={images[currentImage]}
//           alt="Placeholder"
//           className="absolute object-cover w-full h-screen -z-10"
//         />
//       </AnimatePresence>
//     </div>
//   );
// };

// export default Hero;
