import React from "react";

const Product = () => {
  return (
    <div className="w-100 h-[100vh] flex ">
      <img src="hero/comingsoon.jpg" alt="" className="m-auto" />
    </div>
  );
};

export default Product;
