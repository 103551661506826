import React, { useEffect, useState } from "react";
import CountUp from "react-countup";

const Counter = ({ endCount }) => {
  const [startCount, setStartCount] = useState(0);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      // Check if the user has scrolled to a specific position
      const scrollPosition = window.scrollY;
      const triggerPosition = 500; // Example trigger position (pixels from the top)
      if (scrollPosition >= triggerPosition) {
        setShouldAnimate(true);
      }
    };

    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the scroll event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  useEffect(() => {
    if (shouldAnimate) {
      // Set the final count value after a delay (if needed)
      const delay = setTimeout(() => {
        setStartCount(endCount); // Example final count value
      }, 2000); // Example delay of 2000 milliseconds (2 seconds)

      // Cleanup function to clear the timeout on unmount or when shouldAnimate changes
      return () => clearTimeout(delay);
    }
  }, [shouldAnimate]); // Run effect when shouldAnimate changes

  return (
    <div>
      <CountUp start={0} end={startCount} duration={2} separator="," />
    </div>
  );
};

export default Counter;
