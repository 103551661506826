import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./index.css";

// import required modules
import { Navigation, Autoplay } from "swiper/modules";
import { ArrowLeft, ArrowRight } from "react-feather";

export default function Swiperone() {
  const swiperRef = useRef();
  return (
    <>
      <Swiper
        loop={true}
        modules={[Navigation, Autoplay]}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        className="mySwiperone text-white"
      >
        <SwiperSlide>
          <div className="lg:flex items-center">
            <div className="md:w-full lg:w-1/2 ml-1">
              <img
                // style={{ height: "500px" }}
                style={{ objectFit: "cover" }}
                className="md:w-full lg:max-w-[465px] lg:min-h-[310px] 2xl:max-w-[650px] object-cover rounded-xl md:max-h-[400px] mb-4 lg:mb-0"
                src="/photos/prodPhotos/two.png"
                alt=""
              />
            </div>
            <div
              style={{ background: "#22282D" }}
              className="text-start min-h-[272px] lg:w-1/2"
            >
              <button
                style={{
                  background:
                    "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                }}
                className="rounded-full text-[16px] border-1 w-[120px] h-[40px]"
              >
                <span className="w-full">Our Products</span>
              </button>
              <p className="md:text-[32px] text-[20px] font-simibold py-5">
                School Management System
              </p>
              <p className="text-[#C2C7D6] text-[16px] w-[100%]">
                CBS Produce in School management system (SMS) which is a
                software application designed to streamline the administrative
                tasks of educational institutions, including schools, colleges,
                and universities. It typically includes various modules to
                manage student information, academic records, staff details,
                finance, attendance, timetable scheduling, examinations, library
                management, and more. Implementing a school management system
                can greatly improve efficiency, accuracy, and transparency in
                school administration, leading to better outcomes for students,
                teachers, and administrators. It reduces manual paperwork, saves
                time, and provides valuable insights through data analysis.
              </p>
            </div>
          </div>
        </SwiperSlide>
        {/* Item 2 */}
        <SwiperSlide>
          <div className="lg:flex items-center">
            <div className="md:w-full lg:w-1/2 ml-1">
              <img
                style={{ objectFit: "cover" }}
                className="md:w-full lg:max-w-[465px] lg:min-h-[310px] 2xl:max-w-[650px] object-cover rounded-xl md:max-h-[400px] mb-4 lg:mb-0"
                src="/photos/hms.jpg"
                alt=""
              />
            </div>
            <div
              style={{ background: "#22282D" }}
              className="text-start min-h-[272px]  lg:w-1/2"
            >
              <button
                style={{
                  background:
                    "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                }}
                className="rounded-full text-[16px] border-1 w-[120px] h-[40px]"
              >
                <span className="w-full">Our Products</span>
              </button>
              <p className="md:text-[32px] text-[20px] font-simibold py-5">
                Hotel Management System
              </p>
              <p className="text-[#C2C7D6] text-[16px]">
                CBS can delivere a hotel management system (HMS) is designed
                efficiently managing their day-to-day operations.It combines
                various modules and functionalities to streamline tasks such as
                reservations, front desk operations, room management, billing,
                inventory, reporting, and more.hotel management system
                streamlines operations, enhances guest satisfaction, and
                improves overall efficiency in managing a hospitality business.
                It enables hoteliers to deliver exceptional service, optimize
                revenue, and stay competitive in the dynamic hospitality
                industry.
              </p>
            </div>
          </div>
        </SwiperSlide>
        {/* Item 3 */}
        <SwiperSlide>
          <div className="lg:flex items-center">
            <div className="md:w-full lg:w-1/2 ml-1">
              <img
                style={{ objectFit: "cover" }}
                className="md:w-full lg:max-w-[465px] lg:min-h-[310px]  2xl:max-w-[650px] object-cover rounded-xl md:max-h-[400px] mb-4 lg:mb-0"
                src="/photos/prodPhotos/three.png"
                alt=""
              />
            </div>
            <div
              style={{ background: "#22282D" }}
              className="text-start min-h-[272px]  lg:w-1/2"
            >
              <button
                style={{
                  background:
                    "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                }}
                className="rounded-full text-[16px] border-1 w-[120px] h-[40px]"
              >
                <span className="w-full">Our Products</span>
              </button>
              <p className="md:text-[32px] text-[20px] font-simibold py-5">
                Clinic Management System
              </p>
              <p className="text-[#C2C7D6] text-[16px]">
                CBS designed Clinic Management System (CMS) to streamline the
                administrative and clinical operations of healthcare clinics,
                medical practices, and outpatient facilities.It encompasses
                various modules to manage patient appointments, billing and
                invoicing, inventory, staff scheduling, and more.By automating
                administrative tasks and centralizing patient data, CMS empowers
                healthcare providers to deliver high-quality care and improve
                patient outcomes.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="lg:flex items-center">
            <div className="md:w-full lg:w-1/2 ml-1">
              <img
                style={{ objectFit: "cover" }}
                className="md:w-full lg:max-w-[465px] lg:min-h-[310px] 2xl:max-w-[650px] object-cover rounded-xl md:max-h-[400px] mb-4 lg:mb-0"
                src="/photos/prodPhotos/one.png"
                alt=""
              />
            </div>
            <div
              style={{ background: "#22282D" }}
              className="text-start min-h-[272px]  lg:w-1/2"
            >
              <button
                style={{
                  background:
                    "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                }}
                className="rounded-full text-[16px] border-1 w-[120px] h-[40px]"
              >
                <span className="w-full">Our Products</span>
              </button>
              <p className="md:text-[32px] text-[20px] font-simibold py-5">
                HR Management System
              </p>
              <p className="text-[#C2C7D6] text-[16px]">
                Human Resources Management System (HRMS) that combines various
                HR functions and processes into a single platform. These systems
                are designed to streamline HR operations, improve efficiency,
                and enhance decision-making by providing accurate data and
                analytics. Some key functions Employee Data Management ,
                Onboarding/Offboarding , Attendance Tracking , Payroll, KPI ,
                Training, Reporting, etc.
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      {/* <div>
        <button onClick={() => swiperRef.current?.slidePrev()}>Prev</button>
        <button onClick={() => swiperRef.current?.slideNext()}>Next</button>
      </div> */}
      <div
        className="flex justify-end"
        style={{ marginLeft: "auto", width: "auto", height: "auto" }}
      >
        <button
          type="button"
          className="w-auto start-0 z-30 flex items-center justify-center h-full px-1 cursor-pointer group focus:outline-none"
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <div
            style={{
              // background:
              //   "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",

              borderColor:
                "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
              width: "50px",
              height: "50px",
            }}
            className="rounded-full relative border-2"
          >
            <ArrowLeft
              // style={{
              //   color: "red",
              // }}
              class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        </button>
        <button
          type="button"
          className="end-0 z-30 flex items-center justify-center h-full px-1 cursor-pointer group focus:outline-none"
          onClick={() => swiperRef.current?.slideNext()}
        >
          <div
            style={{
              background:
                "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
              width: "50px",
              height: "50px",
            }}
            className="rounded-full relative"
          >
            <ArrowRight class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
          </div>
        </button>
      </div>
    </>
  );
}
