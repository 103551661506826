import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import About from "./About";
import Contact from "./Contact";
import Animation from "./Animation";
import Counter from "./components/Counter";
import Gsap from "./components/Gsap";
import Service from "./components/Service";
import Product from "./components/Product";
import Industries from "./components/Industries";
// import Vanta from "./Vanta";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/service" element={<Service />} />
        <Route path="/product" element={<Product />} />
        <Route path="/industries" element={<Industries />} />

        {/* <Route path="/Animation" element={<Animation />} /> */}
        {/* <Route path="/Animation1" element={<Hero />} /> */}
        {/* <Route path="/Animation2" element={<Counter endCount="800" />} /> */}
        {/* <Route path="/Animation3" element={<Gsap />} /> */}
      </Routes>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
