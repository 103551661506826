import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

const About = () => {
  const location = useLocation();

  const circleRef1 = useRef(null);
  const circleRef2 = useRef(null);
  const circleRef3 = useRef(null);
  const circleRef4 = useRef(null);
  const circleRef5 = useRef(null);
  const circleRef6 = useRef(null);

  const [shouldAnimate1, setShouldAnimate1] = useState(false);
  const [shouldAnimate2, setShouldAnimate2] = useState(false);
  const [shouldAnimate3, setShouldAnimate3] = useState(false);
  const [shouldAnimate4, setShouldAnimate4] = useState(false);
  const [shouldAnimate5, setShouldAnimate5] = useState(false);
  const [shouldAnimate6, setShouldAnimate6] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = circleRef1.current;
      const elementPosition = element.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY;

      // Determine if the element is in view
      if (elementPosition < windowHeight && elementPosition > 0) {
        setShouldAnimate1(true);
      } else {
        setShouldAnimate1(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener
    return () => window.removeEventListener("scroll", handleScroll);
  }, [shouldAnimate1]);

  useEffect(() => {
    const handleScroll = () => {
      const element = circleRef2.current;
      const elementPosition = element.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY;

      // Determine if the element is in view
      if (elementPosition < windowHeight && elementPosition > 0) {
        setShouldAnimate2(true);
      } else {
        setShouldAnimate2(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener
    return () => window.removeEventListener("scroll", handleScroll);
  }, [shouldAnimate2]);

  useEffect(() => {
    const handleScroll = () => {
      const element = circleRef3.current;
      const elementPosition = element.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY;

      // Determine if the element is in view
      if (elementPosition < windowHeight && elementPosition > 0) {
        setShouldAnimate3(true);
      } else {
        setShouldAnimate3(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener
    return () => window.removeEventListener("scroll", handleScroll);
  }, [shouldAnimate3]);

  useEffect(() => {
    const handleScroll = () => {
      const element = circleRef4.current;
      const elementPosition = element.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY;

      // Determine if the element is in view
      if (elementPosition < windowHeight && elementPosition > 0) {
        setShouldAnimate4(true);
      } else {
        setShouldAnimate4(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener
    return () => window.removeEventListener("scroll", handleScroll);
  }, [shouldAnimate4]);

  useEffect(() => {
    const handleScroll = () => {
      const element = circleRef5.current;
      const elementPosition = element.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY;

      // Determine if the element is in view
      if (elementPosition < windowHeight && elementPosition > 0) {
        setShouldAnimate5(true);
      } else {
        setShouldAnimate5(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener
    return () => window.removeEventListener("scroll", handleScroll);
  }, [shouldAnimate5]);

  useEffect(() => {
    const handleScroll = () => {
      const element = circleRef6.current;
      const elementPosition = element.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY;

      // Determine if the element is in view
      if (elementPosition < windowHeight && elementPosition > 0) {
        setShouldAnimate6(true);
      } else {
        setShouldAnimate6(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener
    return () => window.removeEventListener("scroll", handleScroll);
  }, [shouldAnimate6]);

  useEffect(() => {
    // Check if there is a hash in the URL
    if (location.hash) {
      const elementId = location.hash.substring(1); // Remove the '#' from the hash
      const element = document.getElementById(elementId);

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // If there is no hash, scroll to the top of the page
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <div style={{ overflowX: "hidden" }}>
      <div className="text-center pt-5 mt-32">
        <p
          className="md:text-[48px] text-[32px] md:leading-tight leading-9 font-bold py-3"
          style={{ lineHeight: "76px" }}
        >
          About Our Company
        </p>
        <p className="md:max-w-[450px] max-w-[361px] mx-auto text-[#C2C7D6] text-[16px] leading-6">
          Accelerate For The Furture with CBS's Digital Solutions
        </p>

        <div className="lg:flex items-center mx-auto max-w-[1400px] md:gap-[40px] gap-[10px] py-16 md:px-[80px] px-[16px]">
          <div
            data-aos="fade-right"
            className="lg:w-[746px] text-start text-wrap"
          >
            {/* <img
              className="w-full  object-cover lg:h-[580px] md:h-[400px] h-[150px] md:rounded-[50px] rounded-lg"
              src="/photos/photo3.jpg"
              alt=""
            /> */}
            <p
              className="md:pb-2 pb-4 text-[#E0E4EB] text-[16px] indent-16"
              style={{ lineHeight: "28px", textAlign: "justify" }}
            >
              Connected Business Solutions Company - CBS was established in 2018
              and providing Digital Solutions providers in Myanmar. Led by an
              experienced leadership team with more than 5 years of experience
              in Myanmar’s Web Development, System Integration and technology
              industry founded in 2018.
            </p>
            <p
              className="md:pb-2 pb-4 text-[#E0E4EB] text-[16px]  indent-16"
              style={{ lineHeight: "28px", textAlign: "justify" }}
            >
              CBS’s clear vision as a digital solutions leader in Myanmar, the
              growth is underpinned by a rapidly developing Software Development
              sector, a full suite of operating licenses and a well-experienced
              management team led by visionary co-founders that have played a
              pivotal role in advancing the technology industry with more than 5
              years of Development and ICT industry experience, supported by a
              skilled executive team, with an average tenure of over 5 years.We
              serve consumers in Myanmar through our global associates and world
              leading Technology Provider, such as AWS, Huawei, Google and are
              all established brands in their global markets and share our
              commitment to continually invest in our brands, networks,
              processes to deliver a superior customer experience.
            </p>
            {/* <p
              className="md:pb-2 pb-4 text-[#E0E4EB] text-[16px]  indent-16"
              style={{ lineHeight: "28px", textAlign: "justify" }}
            ></p> */}
            <p
              className="md:pb-2 pb-4 text-[#E0E4EB] text-[16px]  indent-16"
              style={{ lineHeight: "28px", textAlign: "justify" }}
            >
              CBS’s provides companies and governments with comprehensive and
              integrated ICT solutions that cover Software Development,
              Infrastructure & Managed Services, Cloud Computing, IT services
              and professional consulting. As a digital transformation provider
              in Myanmar, our suite of ICT solutions is backed up by an
              extensive data network and infrastructure that spans across key
              business cities and markets in Myanmar.
            </p>
            {/* <p
              className="md:pb-2 pb-4 text-[#E0E4EB] text-[16px]  indent-16"
              style={{ lineHeight: "28px", textAlign: "justify" }}
            >
             
            </p> */}
            {/* <p>
              CBS is committed to assisting our clients in achieving their
              desires by utilizing cutting-edge technology solutions.
            </p>
            <p>
              We strongly believe that our company contribute in society more
              than just high-quality software. That’s why we take part in
              socially responsible initiatives, whether it’s educational
              projects, environment protection programs, or community support
              activities.
            </p> */}
          </div>

          <div
            data-aos="fade-left"
            className="lg:w-[476px] w-[100%] lg:h-[580px] md:h-[400px] mx-auto"
          >
            {" "}
            <img
              className="w-full lg:h-[580px] md:h-[400px] h-[150px] object-cover md:rounded-[50px] rounded-lg mx-auto"
              src="/photos/photo3.jpg"
              // style={{ borderRadius: "50px" }}
              alt=""
            />
          </div>
          {/* <img className="col-7 w-full" src="/photos/photo3.jpg" alt="" />
          <img className="col-5" src="/photos/photo4.jpg" alt="" /> */}
        </div>
      </div>

      {/* <div className="text-start container md:flex mx-auto md:px-5 mb-10">
        <div className="md:w-1/3 mx-5">
          <p className="lg:text-5xl md:text-4xl text-[24px] font-simibold md:py-3">
            What we do
          </p>
        </div>
        <div className="md:w-2/3 mx-5 md:py-3">
          <p
            className="md:pb-5 pb-4 text-[#E0E4EB] text-[16px]"
            style={{ lineHeight: "28px" }}
          >
            Connected Business Solutions Company - CBS was established in 2018
            and providing Digital Solutions providers in Myanmar. Led by an
            experienced leadership team with more than 5 years of experience in
            Myanmar’s Web Development, System Integration and technology
            industry founded in 2018.
          </p>
          <p
            className="md:pb-5 pb-4 text-[#E0E4EB] text-[16px]"
            style={{ lineHeight: "28px" }}
          >
            CBS’s clear vision as a digital solutions leader in Myanmar, the
            growth is underpinned by a rapidly developing Software Development
            sector, a full suite of operating licenses and a well-experienced
            management team led by visionary co-founders that have played a
            pivotal role in advancing the technology industry with more than 5
            years of Development and ICT industry experience, supported by a
            skilled executive team, with an average tenure of over 5 years.
          </p>
          <p
            className="md:pb-5 pb-4 text-[#E0E4EB] text-[16px]"
            style={{ lineHeight: "28px" }}
          >
            We serve consumers in Myanmar through our global associates and
            world leading Technology Provider, such as AWS, Huawei, Google and
            are all established brands in their global markets and share our
            commitment to continually invest in our brands, networks, processes
            to deliver a superior customer experience.
          </p>
          <p
            className="md:pb-5 pb-4 text-[#E0E4EB] text-[16px]"
            style={{ lineHeight: "28px" }}
          >
            CBS’s provides companies and governments with comprehensive and
            integrated ICT solutions that cover Software Development,
            Infrastructure & Managed Services, Cloud Computing, IT services and
            professional consulting.
          </p>
        </div>
      </div> */}

      <div className="container mx-auto md:px-8 px-[16px]">
        <p
          className="lg:text-[40px] md:text-4xl text-[24px] max-w-[550px] mb-[8px] font-simibold py-3"
          style={{ lineHeight: "56px" }}
        >
          Our Mission, Vision, Passion
        </p>
        <div className="lg:flex container mx-auto md:px-8">
          <div
            id="ourMission"
            className="lg:w-1/3 md:w-[90%] lg:min-h-[375px] md:min-h-[300px] border-2 md:m-6 mb-6 pb-6 rounded-lg"
          >
            <img
              //   style={{ widows: "40px", height: "36px" }}
              src="/photos/Icon4.png"
              alt=""
              className="m-2 w-[125px] h-[125px]"
            />
            <p className="text-[20px] ps-8 pb-3">Our Mission</p>{" "}
            <p className="px-8 text-[#C2C7D6] text-[16px]">
              Connected Business Solutions-CBS has to be a Successful Digital
              Transformation Company by reliable , innovative and user friendly
              software servisse provider in IT industry and Digital landscape.
            </p>
          </div>
          <div
            id="ourVision"
            style={{
              background:
                "linear-gradient(100.04deg, rgba(0, 123, 255, 0.8) 0%, rgba(5, 223, 173, 0.8) 99.02%)",
            }}
            className="lg:w-1/3 md:w-[90%] lg:min-h-[375px] md:min-h-[300px] border-2 md:m-6 mb-6 pb-6 rounded-lg"
          >
            <img
              //   style={{ widows: "40px", height: "36px" }}
              src="/photos/Icon6.png"
              alt=""
              className="m-2 w-[125px] h-[125px]"
            />
            <p className="text-[20px] ps-8 pb-3">Our Vision </p>{" "}
            <p className="px-8 text-[16px]">
              Connected Business Solutions-CBS is at the core of Digital
              Solutions and Transformation ecosystem and will contribute to
              remain a strong player in th furture, give the strong technology
              backbone.
            </p>
          </div>
          <div className="lg:w-1/3 md:w-[90%] lg:min-h-[375px] md:min-h-[300px] border-2 md:m-6 mb-6 pb-6 rounded-lg">
            <img
              //   style={{ widows: "40px", height: "36px" }}
              src="/photos/Icon5.png"
              alt=""
              className="m-2 w-[125px] h-[125px]"
            />
            <p className="text-[20px] ps-8 pb-3">Our Passion</p>{" "}
            <p className="px-8 text-[#C2C7D6] text-[16px]">
              Generate new and innovative ideas that can be a Digital
              Transformation leader.Excellence in Continuously and strategically
              evolve our platform using customer satisfications.
            </p>
          </div>
        </div>
      </div>

      <div id="portfolio" className="pt-[120px] text-center px-[12px] md:px-0">
        <button
          style={{ background: "#00D7EA", width: "150px", height: "35px" }}
          className="rounded-full px-1 text-[16px] border-1"
        >
          <span className="w-full">Our Portfolio</span>
        </button>
        <p className="md:text-5xl text-[24px] font-simibold pt-[20px]">
          Discover the Tools that Drive Success
        </p>
        <p className="md:w-[60%] mx-auto text-[#C2C7D6] text-[16px] pt-[20px]">
          Unleash innovation and accelerate growth with our dynamic product.
        </p>
        <div className=" lg:max-w-[1063px] mx-auto min-h-[1000px] mt-[32px]">
          <div
            style={{ borderColor: "#007BFF" }}
            className="md:w-[120px] md:h-[120px] w-[80px] h-[80px] rounded-[50%] flex items-center justify-center md:mx-auto circle-animation circle--dash relative"
          >
            <svg
              ref={circleRef1}
              // class="circle--dash w-full h-full"
              className={` w-full h-full ${
                shouldAnimate1 ? "circle--dash" : ""
              }`}
              viewBox="0 0 140 140"
              xmlns="https://www.w3.org/2000/svg"
              perserveaspectratio="xMinYMin meet"
            >
              <circle
                cx="50%"
                cy="50%"
                r="48%"
                stroke="#00BCF0"
                stroke-width="2"
                fill="transparent"
              ></circle>
            </svg>
            <div
              style={{
                background:
                  "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
              }}
              className="md:w-[105px] md:h-[105px] w-[70px] h-[70px] flex justify-center items-center rounded-[50%] text-[21px] circle-animation absolute"
            >
              2019
            </div>
          </div>

          <div
            data-aos="fade-up-right"
            style={{ position: "relative" }}
            className=" lg:min-h-[630px] md:min-h-[580px] md:flex timelineFirstdiv gap-[8px] relavite"
          >
            <div className="md:col-6 w-full pt-[60px] pb-[80px] md:pb-0 timelineFirstdivFirstChild relative">
              <p className="md:text-end text-start mb-6 me-8 ms-16 md:ms-0 text-[18px] font-[800]">
                Website Development
              </p>
              <img
                className="lg:w-[480px] md:w-[300px] w-[80%] lg:h-[380px] md:h-[320px] md:mx-auto ml-auto"
                src="/photos/aboutus/1.jpg"
                alt=""
              />
            </div>
            <div className="md:col-5 hidden md:block w-full pt-[60px] timelineFirstdivSecondChild relative">
              <p className="text-start mb-8 ms-8">
                Static Website <br />
                Dynamic Website <br /> Ecommerce Website
              </p>
            </div>
            <div className="absolute bottom-0 md:left-[50%] md:translate-x-[-50%] left-0">
              <div
                style={{ borderColor: "#007BFF" }}
                className="md:w-[120px] md:h-[120px] w-[80px] h-[80px]  rounded-[50%] flex items-center justify-center mx-auto circle-animation circle--dash relative"
              >
                <svg
                  ref={circleRef2}
                  className={` w-full h-full ${
                    shouldAnimate2 ? "circle--dash" : ""
                  }`}
                  viewBox="0 0 140 140"
                  xmlns="https://www.w3.org/2000/svg"
                  perserveaspectratio="xMinYMin meet"
                >
                  <circle
                    cx="50%"
                    cy="50%"
                    r="48%"
                    stroke="#00BCF0"
                    stroke-width="2"
                    fill="transparent"
                  ></circle>
                </svg>
                <div
                  style={{
                    background:
                      "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                  }}
                  className="md:w-[105px] md:h-[105px] w-[70px] h-[70px] flex justify-center items-center rounded-[50%] text-[21px] circle-animation absolute"
                >
                  2020
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ position: "relative" }}
            data-aos="fade-up-left"
            className=" lg:min-h-[600px] md:min-h-[550px] flex timelineSeconddiv gap-[8px] "
          >
            <div className="md:col-5 hidden md:block w-full pt-[20px] timelineSeconddivFirstChild relative">
              <p className="text-end mb-8 me-8">
                Customized Application <br /> ( iOS, Android )
              </p>
            </div>
            <div className="md:col-6 w-full pt-[20px] pb-[80px] md:pb-0 timelineSeconddivSecondChild relative">
              <p className="text-start mb-6 md:ms-8 ms-16 me-8 text-[18px] font-[600]">
                Application Development{" "}
              </p>
              <img
                className="lg:w-[480px] md:w-[300px] w-[80%] lg:h-[380px] md:h-[320px] md:mx-auto ml-auto"
                src="/photos/aboutus/2.jpg"
                alt=""
              />
            </div>
            <div className="absolute bottom-0 md:left-[50%] md:translate-x-[-50%] ">
              <div
                style={{ borderColor: "#007BFF" }}
                className="md:w-[120px] md:h-[120px] w-[80px] h-[80px]   rounded-[50%] flex items-center justify-center mx-auto circle-animation circle--dash relative"
              >
                <svg
                  ref={circleRef3}
                  className={` w-full h-full ${
                    shouldAnimate3 ? "circle--dash" : ""
                  }`}
                  viewBox="0 0 140 140"
                  xmlns="https://www.w3.org/2000/svg"
                  perserveaspectratio="xMinYMin meet"
                >
                  <circle
                    cx="50%"
                    cy="50%"
                    r="48%"
                    stroke="#00BCF0"
                    stroke-width="2"
                    fill="transparent"
                  ></circle>
                </svg>
                <div
                  style={{
                    background:
                      "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                  }}
                  className="md:w-[105px] md:h-[105px] w-[70px] h-[70px]  flex justify-center items-center rounded-[50%] text-[21px] circle-animation absolute"
                >
                  2021
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ position: "relative" }}
            data-aos="fade-up-right"
            className=" lg:min-h-[600px] md:min-h-[550px] flex timelineThirddiv gap-[8px] "
          >
            <div className="md:col-6 w-full pt-[20px] pb-[80px] md:pb-0 timelineThirddivFirstChild relative">
              <p className="md:text-end text-start mb-6 me-8 ms-16 md:ms-0 text-[18px] font-[800]">
                Cloud Managed & System Integration{" "}
              </p>
              {/* <p className="text-end mb-6 me-8">E-commerce System</p> */}
              <img
                className="lg:w-[480px] md:w-[300px] w-[80%] lg:h-[380px] md:h-[320px] md:mx-auto ml-auto object-cover"
                src="/photos/aboutus/3.jpg"
                alt=""
              />
            </div>
            <div className="md:col-5 hidden md:block w-full pt-[20px] timelineThirddivSecondChild relative">
              {/* <p className="text-start mb-8 ms-8">
                Public Cloud Hosting
                <br />
                - AWS Cloud, Huawei Cloud, Google Cloud <br />
                Local Hosting <br />- On permises server
              </p> */}
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="ms-8"
              >
                <img
                  style={{ width: "15px", height: "15px" }}
                  className="me-2"
                  src="/photos/bullet1.png"
                  alt=""
                />
                <p>Public Cloud Hosting</p>
              </div>
              <p className="text-start" style={{ marginLeft: "3.3rem" }}>
                (AWS Cloud, Huawei Cloud, Google Cloud)
              </p>
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="ms-8"
              >
                <img
                  style={{ width: "15px", height: "15px" }}
                  className="me-2"
                  src="/photos/bullet1.png"
                  alt=""
                />
                <p>Local Hosting</p>
              </div>
              <p className="text-start mb-8" style={{ marginLeft: "3.3rem" }}>
                (On permises server)
              </p>
            </div>
            <div className="absolute bottom-0  md:left-[50%] md:translate-x-[-50%] left-0">
              <div
                style={{ borderColor: "#007BFF" }}
                className="md:w-[120px] md:h-[120px] w-[80px] h-[80px] flex items-center justify-center mx-auto circle-animation circle--dash relative"
              >
                <svg
                  ref={circleRef4}
                  className={` w-full h-full ${
                    shouldAnimate4 ? "circle--dash" : ""
                  }`}
                  viewBox="0 0 140 140"
                  xmlns="https://www.w3.org/2000/svg"
                  perserveaspectratio="xMinYMin meet"
                >
                  <circle
                    cx="50%"
                    cy="50%"
                    r="48%"
                    stroke="#00BCF0"
                    stroke-width="2"
                    fill="transparent"
                  ></circle>
                </svg>
                <div
                  style={{
                    background:
                      "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                  }}
                  className="md:w-[105px] md:h-[105px] w-[70px] h-[70px] flex justify-center items-center rounded-[50%] text-[21px] circle-animation absolute"
                >
                  2022
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ position: "relative" }}
            data-aos="fade-up-left"
            className=" lg:min-h-[600px] md:min-h-[550px] flex timelineFourthdiv gap-[8px] "
          >
            <div className="md:col-5 hidden md:block w-full pt-[20px]  timelineFourthdivFirstChild relative">
              <p className="text-end mb-8 me-8 ">
                School Management System <br />
                Learning Management System <br />
                Hotel Management System <br />
                Clinic Management System
              </p>
            </div>
            <div className="md:col-6 w-full pt-[20px] pb-[80px] md:pb-0 timelineFourthdivSecondChild relative">
              <p className="text-start mb-6 md:ms-8 ms-16 text-[18px] font-[600]">
                Product Development
              </p>
              <img
                className="lg:w-[480px] md:w-[300px] w-[80%] lg:h-[380px] md:h-[320px] md:mx-auto ml-auto object-contain"
                src="/photos/aboutus/4.png"
                alt=""
              />
            </div>
            <div className="absolute bottom-0 md:left-[50%] md:translate-x-[-50%] left-0">
              <div
                style={{ borderColor: "#007BFF" }}
                className="md:w-[120px] md:h-[120px] w-[80px] h-[80px] rounded-[50%] flex items-center justify-center mx-auto circle-animation circle--dash relative"
              >
                <svg
                  ref={circleRef5}
                  className={` w-full h-full ${
                    shouldAnimate5 ? "circle--dash" : ""
                  }`}
                  viewBox="0 0 140 140"
                  xmlns="https://www.w3.org/2000/svg"
                  perserveaspectratio="xMinYMin meet"
                >
                  <circle
                    cx="50%"
                    cy="50%"
                    r="48%"
                    stroke="#00BCF0"
                    stroke-width="2"
                    fill="transparent"
                  ></circle>
                </svg>
                <div
                  style={{
                    background:
                      "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                  }}
                  className="md:w-[105px] md:h-[105px] w-[70px] h-[70px]  flex justify-center items-center rounded-[50%] text-[21px] circle-animation absolute"
                >
                  2023
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ position: "relative" }}
            data-aos="fade-up-right"
            className=" lg:min-h-[600px] md:min-h-[550px] flex timelineFifthdiv gap-[8px] "
          >
            <div className="md:col-6 w-full pt-[20px] pb-[80px] md:pb-0 timelineFifthdivFirstChild relative">
              {/* <p className="text-end mb-6 me-8">E-commerce System</p> */}
              <p className="md:text-end text-start mb-6 md:me-8 ms-16 text-[18px] font-[600]">
                Gateway Solutions
              </p>
              <img
                className="lg:w-[480px] md:w-[300px] w-[80%] lg:h-[380px] md:h-[320px] md:mx-auto ml-auto object-cover"
                src="/photos/aboutus/5.jpg"
                alt=""
              />
            </div>
            <div className="md:col-5 hidden md:block w-full pt-[20px] timelineFifthdivSecondChild relative">
              <p className="text-start mb-8 ms-8">Payment Gateway</p>
            </div>
            <div className="absolute bottom-0 md:left-[50%] md:translate-x-[-50%] left-0">
              <div
                style={{ borderColor: "#007BFF" }}
                className="md:w-[120px] md:h-[120px] w-[80px] h-[80px] rounded-[50%] flex items-center justify-center mx-auto circle-animation circle--dash relative"
              >
                <svg
                  ref={circleRef6}
                  className={` w-full h-full ${
                    shouldAnimate6 ? "circle--dash" : ""
                  }`}
                  viewBox="0 0 140 140"
                  xmlns="https://www.w3.org/2000/svg"
                  perserveaspectratio="xMinYMin meet"
                >
                  <circle
                    cx="50%"
                    cy="50%"
                    r="48%"
                    stroke="#00BCF0"
                    stroke-width="2"
                    fill="transparent"
                  ></circle>
                </svg>
                <div
                  style={{
                    background:
                      "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                  }}
                  className="md:w-[105px] md:h-[105px] w-[70px] h-[70px]  flex justify-center items-center rounded-[50%] text-[21px] circle-animation absolute"
                >
                  2024
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ position: "relative" }}
            data-aos="fade-up-left"
            className=" lg:min-h-[500px] md:min-h-[350px] flex timelineSixthhdiv gap-[8px] "
          >
            <div className="md:col-5 hidden md:block w-full pt-[20px] timelineSixthhdivFirstChild relative">
              <p className="text-end mb-8 me-8">SMS Gateway </p>
            </div>
            <div className="md:col-6 w-full pt-[20px] pb-[80px] md:pb-0 timelineSixthhdivSecondChild relative">
              <p className="text-start mb-6 md:ms-8 ms-16 text-[18px] font-[600]">
                Gateway Solutions
              </p>
              <img
                className="lg:w-[480px] md:w-[300px] w-[80%] lg:h-[380px] md:h-[320px] md:mx-auto ml-auto"
                src="/photos/aboutus/6.jpg"
                alt=""
              />
            </div>
            {/* <div className="absolute bottom-0 left-[50%] translate-x-[-50%]">
              <div
                style={{ borderColor: "#007BFF" }}
                className="w-[120px] h-[120px] rounded-[50%] border flex items-center justify-center mx-auto"
              >
                <div
                  style={{
                    background:
                      "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                  }}
                  className="w-[105px] h-[105px] flex justify-center items-center rounded-[50%] text-[21px]"
                >
                  2019
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* <svg
        class="circle--dash"
        viewBox="0 0 140 140"
        xmlns="https://www.w3.org/2000/svg"
        perserveaspectratio="xMinYMin meet"
      >
        <circle
          cx="50%"
          cy="50%"
          r="48%"
          stroke="#00BCF0"
          stroke-width="2"
          fill="transparent"
        ></circle>
      </svg> */}

      <div
        style={{
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="max-w-[1280px] card2bg md:mx-[80px] md:my-[120px] lg:mx-auto p-8 rounded-xl md:mb-28 mb-12 mt-12  mx-[2px] md:mx-[0px] text-center"
      >
        <p className="md:text-5xl text-[16px] font-bold py-3 mx-auto">
          Our Dedicated Team is Here <br />
          to provide you
        </p>
        <p className="md:text-[16px] text-[12px]">
          Requesting a demo is the perfect way to explore the features,
          benefits, and possibilities that our solution offers.
        </p>
        <div className="relative mx-auto my-3 md:h-[45px] h-[30px] md:w-[513px] w-[274px]">
          <input
            className="w-full h-full rounded-full text-black text-[12px] md:text-[16px] ps-3"
            type="text"
            placeholder="Enter Your Email"
          />
          <button
            style={{
              background:
                "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            className="md:w-40 w-[100px] md:h-[36px] h-[22px] rounded-full md:text-[16px] text-[10px] md:right-[6px] right-[5px]"
          >
            Request a Demo
          </button>
        </div>

        <p className="md:text-[14px] text-[12px] md:w-[400px] mx-auto">
          By clicking Sign Up you're confirming that you agree with our Terms
          and Conditions.
        </p>
      </div>
    </div>
  );
};

export default About;
