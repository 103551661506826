import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./index.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

export default function SliderThree() {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + "</span>";
    },
  };

  return (
    <>
      <Swiper
        loop={true}
        // modules={[Pagination]}
        modules={[Navigation, Autoplay]}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        className="mySwiper text-white"
      >
        <SwiperSlide className="text-white lg:max-w-[1280px] xl:max-w-[1600px] bg-[#23282E]">
          {" "}
          <div className="lg:flex">
            <div className="lg:w-[60%] xl:w-auto md:mt-8 lg:mt-0 border">
              <img
                className="md:max-h-[500px] lg:max-w-[739px] w-full lg:object-contain"
                src="/photos/paymentGateway.jpg"
                alt=""
              />
            </div>
            <div className="lg:w-[40%] xl:w-[50%] flex flex-col justify-center items-center p-5 gap-[16px] h-[338px] lg:h-auto">
              {" "}
              <p className="md:text-[32px] text-[24px] font-[700] md:leading-[64px] leading-[40px]">
                CBS Payment Gateway
              </p>
              <div className="flex flex-col gap-[24px]">
                <p className="md:text-[24px] text-[18px] font-[500] md:leading-[64px] leading-[48px]">
                  Connecting CBS's Payment Gateway for Simplifying Transactions
                </p>
                <button
                  style={{
                    background:
                      "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                  }}
                  className="w-[176px] h-[50px] text-[14px] rounded mx-auto"
                >
                  Read more
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="text-white lg:max-w-[1280px] xl:max-w-[1600px] bg-[#23282E]">
          {" "}
          <div className="flex flex-col lg:flex-row-reverse">
            <div className="lg:w-[60%] xl:w-auto md:mt-8 lg:mt-0 border">
              <img
                className="md:max-h-[500px] lg:max-w-[739px]  w-fulllg: object-contain"
                src="/photos/smsGateway.jpg"
                alt=""
              />
            </div>
            <div className="lg:w-[40%] xl:w-[50%] flex flex-col justify-center items-center p-5 gap-[16px] h-[338px] lg:h-auto">
              {" "}
              <p className="md:text-[32px] text-[24px] font-[700] md:leading-[64px] leading-[40px]">
                CBS SMS Gateway
              </p>
              <div className="flex flex-col gap-[24px]">
                <p className="md:text-[24px] text-[18px] font-[500] md:leading-[64px] leading-[48px]">
                  Delivering Messages Building Relationships
                </p>
                <button
                  style={{
                    background:
                      "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                  }}
                  className="w-[176px] h-[50px] text-[14px] rounded mx-auto"
                >
                  Read more
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="text-white lg:max-w-[1280px] xl:max-w-[1600px] bg-[#23282E]">
          {" "}
          <div className="lg:flex">
            <div className="lg:w-[60%] xl:w-auto md:mt-8 lg:mt-0">
              <img
                className="md:max-h-[500px] lg:max-w-[739px] w-full lg:object-contain"
                src="/photos/paymentGateway.jpg"
                alt=""
              />
            </div>
            <div className="lg:w-[40%] xl:w-[50%] flex flex-col justify-center items-center p-5 gap-[16px] h-[338px] lg:h-auto">
              {" "}
              <p className="md:text-[32px] text-[24px] font-[700] md:leading-[64px] leading-[40px]">
                CBS Payment Gateway
              </p>
              <div className="flex flex-col gap-[24px]">
                <p className="md:text-[24px] text-[18px] font-[500] md:leading-[64px] leading-[48px]">
                  Connecting CBS's Payment Gateway for Simplifying Transactions
                </p>
                <button
                  style={{
                    background:
                      "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                  }}
                  className="w-[176px] h-[50px] text-[14px] rounded mx-auto"
                >
                  Read more
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="text-white lg:max-w-[1280px] xl:max-w-[1600px] bg-[#23282E]">
          {" "}
          <div className="flex flex-col lg:flex-row-reverse">
            <div className="lg:w-[60%] xl:w-auto md:mt-8 lg:mt-0">
              <img
                className="md:max-h-[500px] lg:max-w-[739px]  w-fulllg: object-contain"
                src="/photos/smsGateway.jpg"
                alt=""
              />
            </div>
            <div className="lg:w-[40%] xl:w-[50%] flex flex-col justify-center items-center p-5 gap-[16px] h-[338px] lg:h-auto">
              {" "}
              <p className="md:text-[32px] text-[24px] font-[700] md:leading-[64px] leading-[40px]">
                CBS SMS Gateway
              </p>
              <div className="flex flex-col gap-[24px]">
                <p className="md:text-[24px] text-[18px] font-[500] md:leading-[64px] leading-[48px]">
                  Delivering Messages Building Relationships
                </p>
                <button
                  style={{
                    background:
                      "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                  }}
                  className="w-[176px] h-[50px] text-[14px] rounded mx-auto"
                >
                  Read more
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
