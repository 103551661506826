import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { isMobile } from "react-device-detect";
import { Link, useLocation } from "react-router-dom";
// import {
//   BookOpenIcon,
//   Bars3BottomRightIcon,
//   XMarkIcon,
// } from "@heroicons/react/24/solid";

import "./App.css";
import { Menu, X } from "react-feather";

const Navbar = () => {
  const location = useLocation();

  const [scrolling, setScrolling] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  console.log(location);

  const isHome = location.pathname === "/";
  const isAbout = location.pathname === "/about";
  const isProduct = location.pathname === "/product";
  const isIndustries = location.pathname === "/industries";
  const isService = location.pathname === "/service";

  console.log(isHome);

  let Links = [
    { name: "Home", link: "/" },
    { name: "Service", link: "/#" },
    { name: "Product", link: "/#" },
    { name: "Industries", link: "/#" },
    { name: "About Us", link: "/about" },
  ];
  let [open, setOpen] = useState(false);

  console.log(location?.pathname);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    // <nav
    //   style={{
    //     fontFamily: "Inter",
    //     // background: `${scrolling ? "#65A30D" : "transparent"}`,
    //   }}
    //   // ${
    //   //   scrolling ? "bg-lime" : "bg-transparent"
    //   // }
    //   className={`dark:bg-gray-900 absolute w-full z-40 top-0 left-0 border-gray-200 dark:border-gray-600 transition-all duration-700 ease-in-out`}
    // >
    //   <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
    //     <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
    //       <img
    //         style={{ width: "58px", height: "56px" }}
    //         src="/logo.png"
    //         // className="h-8"
    //         alt="Flowbite Logo"
    //       />
    //       <span
    //         style={{
    //           fontWeight: "600",
    //           color: "#F5F5F5",
    //           // display: "none",
    //         }}
    //         className="self-center hidden md:block font-bold whitespace-nowrap dark:text-white text-[24px]"
    //       >
    //         Connected Business Solution
    //       </span>
    //     </a>
    //     <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
    //       <a href="/contact">
    //         <button
    //           type="button"
    //           style={{
    //             background:
    //               "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
    //             width: "184px",
    //             height: "47px",
    //             fontSize: "18px",
    //           }}
    //           className="text-white hidden md:block bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
    //         >
    //           Contact us
    //         </button>
    //       </a>
    //       <button
    //         data-collapse-toggle="navbar-sticky"
    //         type="button"
    //         className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
    //         aria-controls="navbar-sticky"
    //         aria-expanded="false"
    //       >
    //         <span className="sr-only">Open main menu</span>
    //         <svg
    //           className="w-5 h-5"
    //           aria-hidden="true"
    //           xmlns="http://www.w3.org/2000/svg"
    //           fill="none"
    //           viewBox="0 0 17 14"
    //         >
    //           <path
    //             stroke="currentColor"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth="2"
    //             d="M1 1h15M1 7h15M1 13h15"
    //           />
    //         </svg>
    //       </button>
    //     </div>
    //     <div
    //       className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
    //       // id="navbar-sticky"
    //     >
    //       <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
    //         <li>
    //           <a
    //             href="/"
    //             className={`block py-2 px-3 text-white text-[20px]
    //             ${location?.pathname == "/" && "bg-blue-700 md:text-blue-700"}
    //             rounded md:bg-transparent  md:p-0 md:dark:text-blue-500`}
    //             aria-current="page"
    //           >
    //             Home
    //           </a>
    //         </li>
    //         <li>
    //           <a
    //             href="/#"
    //             className="block py-2 px-3 text-white text-[20px] rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
    //           >
    //             Service
    //           </a>
    //         </li>
    //         <li>
    //           <a
    //             href="#"
    //             className="block py-2 px-3 text-white text-[20px] rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
    //           >
    //             Products
    //           </a>
    //         </li>
    //         <li>
    //           <a
    //             href="#"
    //             className="block py-2 px-3 text-white text-[20px] rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
    //           >
    //             Work
    //           </a>
    //         </li>
    //         <li>
    //           <a
    //             href="/about"
    //             className={`block py-2 px-3 text-white text-[20px] ${
    //               location?.pathname == "/about" && "md:text-blue-700"
    //             }  rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
    //           >
    //             About Us
    //           </a>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    // </nav>
    // src/components/Navbar.js

    <div
      className=" max-w-[1400px] mx-auto w-full fixed align-center bg-black md:bg-transparent md:absolute top-0 left-[50%] transform translate-x-[-50%]"
      style={{ zIndex: "1000000" }}
    >
      <div
        data-aos="fade-down"
        className="md:flex items-center justify-between md:bg-transparent py-4 lg:px-10 px-7 md:px-3"
      >
        {/* logo section */}
        <div className="font-bold text-2xl cursor-pointer flex items-center gap-1">
          <a
            href="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img
              style={{ width: "58px", height: "56px" }}
              src="/logo.png"
              className="h-8"
              alt="Flowbite Logo"
            />
            <span
              style={{
                fontWeight: "600",
                color: "#F5F5F5",
                // display: "none",
              }}
              className="self-center hidden xl:block font-bold whitespace-nowrap dark:text-white text-[24px]"
            >
              Connected Business Solution
            </span>
          </a>
        </div>
        {/* Menu icon */}
        <div
          onClick={() => setOpen(!open)}
          className="absolute right-8 top-6 cursor-pointer md:hidden w-7 h-7"
        >
          {/* {open ? <XMarkIcon /> : <Bars3BottomRightIcon />} */}
          {open ? <X /> : <Menu />}
        </div>
        {/* linke items */}
        <ul
          className={`md:flex md:bg-transparent bg-white md:items-center md:pb-0 pb-12 absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
            open ? "top-18" : "top-[-490px]"
          }`}
        >
          {/* {Links.map((link) => (
            <li className="md:ml-8 md:my-0 my-7">
              <a
                href={link.link}
                className="md:text-white text-black hover:text-blue-400 duration-500"
              >
                {link.name}
              </a>
            </li>
          ))} */}
          <li className="md:ml-8 md:my-0 my-7">
            <a
              href="/"
              className={`text-black hover:text-blue-400 duration-500 ${
                isHome ? "md:text-[#D3D924] text-bold " : "md:text-white "
              }`}
            >
              Home
            </a>
          </li>
          <li className="md:ml-8 md:my-0 my-7">
            <a
              href="/service"
              className={`text-black hover:text-blue-400 duration-500 ${
                isService ? "md:text-[#D3D924] text-bold " : "md:text-white "
              }`}
            >
              Service
            </a>
          </li>
          <li className="md:ml-8 md:my-0 my-7">
            <a
              href="/product"
              className={`text-black hover:text-blue-400 duration-500 ${
                isProduct ? "md:text-[#D3D924] text-bold " : "md:text-white "
              }`}
            >
              Product
            </a>
          </li>
          <li className="md:ml-8 md:my-0 my-7">
            <a
              href="/industries"
              className={`text-black hover:text-blue-400 duration-500 ${
                isIndustries ? "md:text-[#D3D924] text-bold " : "md:text-white "
              }`}
            >
              Industries
            </a>
          </li>
          <li className="md:ml-8 md:my-0 my-7">
            <a
              href="/about"
              className={`text-black hover:text-blue-400 duration-500 ${
                isAbout ? "md:text-[#D3D924] text-bold " : "md:text-white "
              }`}
            >
              About Us
            </a>
          </li>
          {/* <button className="btn bg-blue-600 text-white md:ml-8 px-3 py-1 rounded duration-500 md:static"> */}
          <a href="/contact">
            <button
              type="button"
              style={{
                background:
                  "linear-gradient(100.04deg, #007BFF 0%, #05DFAD 99.02%)",
                width: "184px",
                height: "47px",
                fontSize: "18px",
              }}
              className="text-white md:ml-8 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Contact us
            </button>
          </a>
          {/* </button> */}
        </ul>
        {/* button */}
      </div>
    </div>
  );
};

export default Navbar;
